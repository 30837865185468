<script lang="ts" setup>
import gsap from 'gsap'
import { computed } from 'vue'
const props = defineProps({
  color: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: 'icon-arrow-right'
  },
  status: {
    type: String,
    default: ''
  },
  bg: {
    type: String,
    default: 'white'
  }
})

const leave = ref(false)
const colorClass = computed(() => (!leave.value || props.bg !== 'white' ? props.color : ''))

const shape1 =
  'M14.4,14.4c14.4,0,39.8,0,53.3,0s44.1,0,53.3,0c0,12.2,0,40,0,53.3s0,43.4,0,53.3c-12.8,0-37.8,0-53.3,0c-20.9,0-39.7,0-53.3,0c0-13.8,0-40.5,0-53.3S14.4,21.2,14.4,14.4'
const shape2 =
  'M25.9,22.2C39,11.6,52.5,5.1,66,5.1s31.4,5.1,46.3,16.6c10,11.1,15.5,32.7,15.5,46s-4.4,39.4-19.5,50.4c-11.9,7.8-28.7,11.3-44.1,11.3c-20.9,0-32.8-7.1-40.4-18.3C16.3,96.5,5.5,83.2,5.5,70.4S15.8,28.6,25.9,22.2'

const refCircle = ref()

const handelEnter = () => {
  leave.value = true
  gsap.to(refCircle.value, {
    duration: 0.4,
    ease: 'back.out(1.7)',
    attr: { d: shape2 }
  })
}

const handleLeave = () => {
  leave.value = false
  gsap.to(refCircle.value, {
    duration: 0.4,
    ease: 'back.out(1.7)',
    attr: { d: shape1 }
  })
}
</script>
r

<template>
  <nuxt-link class="link" :class="colorClass">
    <div class="link__slot">
      <slot />
    </div>
    <svg-icon class="link__icon" :name="props.icon" v-if="props.status === ''" />
    <div v-else-if="props.status === 'loading'">
      <Loading />
    </div>
    <div v-else-if="props.status === 'done'"><svg-icon name="icon-done" class="link__icon" /></div>
    <div class="circle" @mouseover="handelEnter" @mouseout="handleLeave">
      <svg viewBox="0 0 135.4 135.4">
        <path ref="refCircle" fill="currentColor"
          d="M14.4,14.4c14.4,0,39.8,0,53.3,0s44.1,0,53.3,0c0,12.2,0,40,0,53.3s0,43.4,0,53.3c-12.8,0-37.8,0-53.3,0c-20.9,0-39.7,0-53.3,0c0-13.8,0-40.5,0-53.3S14.4,21.2,14.4,14.4" />
      </svg>
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.link {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: toRem(132);
  height: toRem(132);
  font-size: toRem(18);

  @include max-media(990) {
    width: toRem(120);
    height: toRem(120);
    font-size: toRem(16);
  }

  &__slot,
  &__icon {
    pointer-events: none;
  }

  &__slot {
    text-align: center;

    @include max-media(990) {
      padding-left: toRem(15);
      padding-right: toRem(15);
    }
  }

  &__icon {
    width: toRem(24);
    height: toRem(24);
    margin-top: toRem(2);
    color: $color-gray-1;
  }

  &.white {
    color: $color-white-1;

    .link {
      &__slot {
        color: $color-white-1;
      }
    }

    .circle {
      svg {
        stroke: $color-white-1;
      }
    }
  }

  &.noBorder {
    .circle {
      opacity: 0;
    }
  }

  &.gray-3 {

    // border-color: $color-gray-3;
    // background-color: $color-gray-3;
    .circle {
      svg {
        color: $color-gray-3;
      }
    }
  }

  .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: calc(100% + toRem(30));
    height: calc(100% + toRem(30));

    @include max-media(990) {
      // width: calc(100% + toRem(6));
      // height: calc(100% + toRem(6));
    }

    svg {
      width: 100%;
      height: 100%;
      color: transparent;
      stroke: $color-gray-line;
    }

    &:hover {
      svg {
        animation: 2s rotate linear infinite;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
